<template>
  <a-modal v-model:visible="datadetailShow" width="70%" :title="title" footer="">
    <div>
      <div>
        <a-form layout="inline" :model="searchForm">
          <a-form-item label="">
            <a-cascader :options="$store.state.app.cityDict" v-model:value="startData" :show-search="{ filter }"
              :changeOnSelect="true" @change="setStartAddress" placeholder="起点" allowClear />
            <!-- <a-input v-model:value="searchForm.startAddress" placeholder="起点" allowClear /> -->
          </a-form-item>
          <a-form-item label="">
            <a-cascader :options="$store.state.app.cityDict" v-model:value="endData" :show-search="{ filter }"
              :changeOnSelect="true" @change="setEndAddress" placeholder="终点" allowClear />
            <!-- <a-input v-model:value="searchForm.endAddress" placeholder="终点" allowClear /> -->
          </a-form-item>
          <a-form-item label="">
            <a-input v-model:value="searchForm.qualityLossType" placeholder="质损类型" allowClear />
          </a-form-item>
          <a-form-item label="">
            <a-input v-model:value="searchForm.carNo" placeholder="车牌号" allowClear />
          </a-form-item>
          <a-form-item label="">
            <a-space>
              <a-button :loading="loading" type="primary" @click="searchData">
                查询
              </a-button>
              <a-button @click="resetData"> 重置 </a-button>
            </a-space>
          </a-form-item>
        </a-form>
        <a-table :columns="[sortColumn, ...columns]" :row-key="record => record.id" :pagination="pagination"
          :data-source="listData" :loading="loading" :rowKey="(record, index) => { return index }" bordered
          @change="handleTableChange">
          <template #sort="{ index }">
            {{ index + 1 }}
          </template>

          <template #brand="{ record }">
            <a @click="openDetail(record)">{{ record.orderVehicleVin }}</a> -<span>{{ record.brand + record.model
              }}</span>
          </template>
        </a-table>
      </div>
    </div>
    <a-modal v-model:visible="detailShow" footer="" :getContainer="false" width="65%"
      :bodyStyle="{ padding: '15px', paddingTop: 0, paddingRight: 0 }">

      <template #title>
        <WindowDetail v-if="detailData.vinNo" :detailData="detailData" />
      </template>
      <div class="left-content" style="width: auto">
        <TransportModel ref="transportModelRef" :windowShow="detailShow" :data="detailData" :detailType="true"
          @statusShow="transportModelShowStatus" />
      </div>
    </a-modal>
  </a-modal>
</template>

<script>
import { reactive, toRefs, ref, onMounted } from 'vue'
import { massLossDetail } from '@/api/carrier/carrierList'
import WindowDetail from '@/views/components/vinNo/windowDetail.vue'
import TransportModel from '@/views/components/transport/smallVehicleOperate/transportModel.vue'

export default {
  props: {
    carrierId: String,
    carrierData: Object
  },
  components: {
    WindowDetail,
    TransportModel
  },
  setup (props) {
    const transportModelRef = ref(null)
    const state = reactive({
      datadetailShow: false,
      loading: false,
      detailShow: false,
      title: '',
      listData: [],
      endData: [],
      startData: [],
      searchForm: {
        carNo: '',
        qualityLossType: '',
        startAddress: {},
        endAddress: {}
      },
      detailData: {},
      mirrorSearchForm: {},
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      sortColumn: { title: '序号', slots: { customRender: 'sort' } },
      columns: [
        {
          title: '车牌/车型',
          dataIndex: 'brand',
          align: 'center',
          slots: {
            customRender: 'brand'
          }
        },
        {
          title: '订单号',
          dataIndex: 'orderId',
          align: 'center'
        },
        {
          title: '业务员',
          dataIndex: 'salesman',
          align: 'center'
        },
        {
          title: '起点',
          dataIndex: 'startDetails',
          align: 'center'
        },
        {
          title: '终点',
          dataIndex: 'endDetails',
          align: 'center'
        },
        {
          title: '下单时间',
          dataIndex: 'createTime',
          align: 'center'
        },
        {
          title: '质损类型',
          dataIndex: 'lossTypes',
          align: 'center'
        },
        {
          title: '质损金额',
          dataIndex: 'qualityLossAmt',
          align: 'center'
        },
        {
          title: '是否保险理赔',
          dataIndex: 'isClaim.label',
          align: 'center'
        },
        {
          title: '赔付金额',
          dataIndex: 'qualityLossAmt',
          align: 'center'
        },
        {
          title: '备注',
          dataIndex: 'remark',
          align: 'center'
        }
      ]
    })
    onMounted(() => {
      state.mirrorSearchForm = JSON.stringify(state.searchForm)
    })
    const loadData = () => {
      state.loading = true
      state.title = props.carrierData.carrierName + '—质损车辆'
      massLossDetail({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.pageSize,
        objectId: props.carrierId
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.pagination.total = res.data.total
        }
      }).finally(() => {
        state.loading = false
      })
    }
    const setStartAddress = (e, v) => {
      state.searchForm.startAddress.province = e[0]
      state.searchForm.startAddress.city = e[1]
      state.searchForm.startAddress.area = e[2]
    }
    const setEndAddress = (e, v) => {
      state.searchForm.endAddress.province = e[0]
      state.searchForm.endAddress.city = e[1]
      state.searchForm.endAddress.area = e[2]
    }
    const handleTableChange = page => {
      state.pagination = page
      loadData()
    }
    const searchData = () => {
      state.pagination.current = 1
      loadData()
    }
    const resetData = () => {
      state.startData = {}
      state.endData = {}
      state.searchForm = JSON.parse(state.mirrorSearchForm)
      searchData()
    }
    const openDetail = record => {
      state.detailData = record
      state.detailShow = true
      setTimeout(() => {
        transportModelRef.value.getTaskListData()
      }, 50)
    }
    const transportModelShowStatus = (e) => {
      if (e.orderId) state.detailData = e
    }

    return {
      ...toRefs(state),
      transportModelRef,
      loadData,
      transportModelShowStatus,
      handleTableChange,
      setStartAddress,
      setEndAddress,
      openDetail,
      searchData,
      resetData
    }
  }
}
</script>

<style lang="less" scoped>
:deep(.ant-form-item) {
  width: 18%;
  margin: 5px;
}
</style>
