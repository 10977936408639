<template>
  <div style="background:#fff;padding:10px">
    <div style="display: flex;justify-content: space-between;align-items: center;margin-bottom:10px">
      <div style="font-size: 18px;color: #344563;font-weight: 700;">质损统计</div>
      <a-form :model="formState" layout="inline">
        <a-form-item label="">
          <a-select v-model:value="searchForm.periodType" style="width:150px" placeholder="承运商类型">
            <a-select-option :value="1">未审核</a-select-option>
            <a-select-option :value="2">新入期</a-select-option>
            <a-select-option :value="3">试用期</a-select-option>
            <a-select-option :value="4">合作期</a-select-option>
            <a-select-option :value="5">淘汰期</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="">
          <a-select v-model:value="searchForm.sortParam" style="width:150px" placeholder="按指定类型排名">
            <a-select-option :value="1">承运台量</a-select-option>
            <a-select-option :value="2">质损台量</a-select-option>
            <a-select-option :value="3">质损率</a-select-option>
            <a-select-option :value="4">质损金额</a-select-option>
            <a-select-option :value="5">赔付金额</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="">
          <a-select v-model:value="searchForm.sort" style="width:150px" placeholder="降序">
            <a-select-option :value="1">降序</a-select-option>
            <a-select-option :value="2">升序</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="searchForm.carrierName" placeholder="承运商名称" />
        </a-form-item>
        <a-form-item label="">
          <a-space>
            <a-button type="primary" @click="searchData" :loading="loading">搜索</a-button>
            <a-button @click="resetData" :loading="loading">重置</a-button>
          </a-space>
        </a-form-item>
      </a-form>
    </div>
    <a-table :columns="[sortColumn, ...columns]" :row-key="record => record.id" :pagination="pagination" :data-source="listData"
      :loading="loading" :rowKey="(record, index) => { return index }" bordered @change="handleTableChange">
      <template #sort="{ index }">
        {{ index + 1 }}
      </template>

      <template #carrierName="{ record }">
        <div>
          <a @click="openDetail(record)">{{ record.carrierName }}</a>
        </div>
      </template>

      <template #lossNum="{ record }">
        <div>
          <a @click="openTimeout(record)" v-show="record.lossNum > 0">{{ record.lossNum }}</a>
          <span v-show="record.lossNum <= 0">{{ record.lossNum }}</span>
        </div>
      </template>

      <template #lossRate="{ record }">
        <div>
          <span>{{ record.lossRate + '%'}}</span>
        </div>
      </template>
    </a-table>
    <a-drawer width="80%" placement="right" v-model:visible="detailShow" destroyOnClose
      @close="detailShow = false, loadData()">
      <CarrierDetail :carrierId="carrierId" :detail="carrierData" type="1" />
    </a-drawer>
    <MassLossNum ref="massLossNumRef" :carrierId="carrierId" :carrierData="carrierData" />
  </div>

</template>

<script>
import { reactive, toRefs, ref, onMounted } from 'vue'
import { massLossPage } from '@/api/carrier/carrierList'
import CarrierDetail from '@/views/carrier/toolDetail/index.vue'
import MassLossNum from './massLossNum.vue'

export default {
  components: {
    MassLossNum,
    CarrierDetail
  },
  setup () {
    const massLossNumRef = ref(null)
    const state = reactive({
      loading: false,
      detailShow: false,
      datadetailShow: false,
      carrierId: '',
      carrierData: {},
      listData: [],
      searchForm: {
        sort: null,
        sortParam: null,
        periodType: null,
        carrierName: ''
      },
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      sortColumn: { title: '排名', slots: { customRender: 'sort' } },
      columns: [
        {
          title: '承运商名称',
          dataIndex: 'carrierName',
          align: 'center',
          slots: {
            customRender: 'carrierName'
          }
        },
        {
          title: '当前评分',
          dataIndex: 'levelScore',
          align: 'center'
        },
        {
          title: '所属周期',
          dataIndex: 'periodType.label',
          align: 'center'
        },
        {
          title: '承运台量',
          dataIndex: 'totalNum',
          align: 'center'
        },
        {
          title: '质损台量',
          dataIndex: 'lossNum',
          align: 'center',
          slots: {
            customRender: 'lossNum'
          }
        },
        {
          title: '质损率',
          dataIndex: 'lossRate',
          align: 'center',
          slots: {
            customRender: 'lossRate'
          }
        },
        {
          title: '质损总金额',
          dataIndex: 'lossAmt',
          align: 'center'
        },
        {
          title: '赔付总金额',
          dataIndex: 'lossIndemnityAmt',
          align: 'center'
        }
      ]
    })
    onMounted(() => {
      loadData()
    })
    const loadData = () => {
      state.loading = true
      massLossPage({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        state.listData = res.data.records
        state.pagination.total = res.data.total
      }).finally(() => {
        state.loading = false
      })
    }
    const openDetail = (record) => {
      state.carrierId = record.carrierId
      state.carrierData = record
      state.detailShow = true
    }
    const openTimeout = record => {
      state.carrierId = record.carrierId
      state.carrierData = record
      setTimeout(() => {
        massLossNumRef.value.datadetailShow = true
        massLossNumRef.value.loadData()
      }, 10)
    }
    const searchData = () => {
      state.pagination.current = 1
      loadData()
    }
    const resetData = () => {
      state.searchForm = {}
      searchData()
    }
    const handleTableChange = page => {
      state.pagination = page
      loadData()
    }

    return {
      ...toRefs(state),
      massLossNumRef,
      loadData,
      searchData,
      resetData,
      openDetail,
      openTimeout,
      handleTableChange
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
